<template>
	<Toast />
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer"
				>Inventario</a
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3 active">Movimientos</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-12 lg:px-12">
		<div
			class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
		>
			<h4><strong>Movimientos de Inventario (Ingreso/Egreso)</strong></h4>
		</div>
		<div class="col-12">
			<div class="p-fluid formgrid grid">
				<div class="field col-12 md:col-3">
					<label for="sucursal"><strong>Seleccione Sucursal:</strong></label>
					<Dropdown
						v-model="sucursalSelected"
						:options="sucursales"
						optionLabel="nombre"
						optionValue="id"
					>
					</Dropdown>
				</div>
				<div class="field col-12 md:col-3">
					<label for=""><strong>Tipo Movimiento:</strong></label>
					<Dropdown
						v-model="tipoMovimientoSelected"
						:options="tipoMovimientos"
						optionLabel="nombre"
						placeholder="Seleccione Tipo de Movimiento"
					>
						<template #option="slotProps">
							<div>
								{{ slotProps.option.nombre }} -
								{{ slotProps.option.tipo }}
							</div>
						</template>
					</Dropdown>
				</div>
				<div class="field col-12 md:col-4">
					<label for=""><strong>Nombre Producto:</strong></label>
					<AutoComplete
						:dropdown="true"
						field="descripcion"
						v-model="productoSelected"
						:suggestions="productosListadoFiltrado"
						@complete="buscarProducto($event)"
						placeholder="Buscar Producto"
					>
						<template #item="slotProps">
							<div>
								{{ slotProps.item.descripcion }} -
								{{ slotProps.item.fabrica_nombre }}
							</div>
						</template>
					</AutoComplete>
					<small class="p-invalid" v-if="errors.productoSelected">{{
						errors.productoSelected[0]
					}}</small>
				</div>

				<!-- <div class="field col-12 md:col-2">
					<label for="fecha_movimiento"
						><strong>Fecha Movimiento:</strong></label
					>
					<Calendar
						id="fecha_movimiento"
						v-model="fecha_movimiento"
						:showIcon="true"
						dateFormat="dd/mm/yy"
            disabled
					/>
				</div> -->
				<div class="field col-12 md:col-2">
					<label for="fecha_movimiento"
						><strong>Agregar: </strong></label
					>
					<Button
					label="Agregar al Detalle"
					icon="pi pi-plus"
					class="p-button-primary"
					v-tooltip.top="'Agregar al Producto al detalle'"
					@click="agregarProductoDetalle"
				/>
				</div>
			</div>
			<div class="mt-4 col-12">
				<DataTable
					ref="reporte_stock"
					key="id"
					:value="detalle_movalmacen"
					responsiveLayout="scroll"
					:loading="enviando"
					class="p-datatable-sm"
					responsive="true"
					editMode="cell"
					@cell-edit-complete="guardandoCambiosCelda"
				>
					<template #loading
						><div class="flex align-items-center justify-content-center">
							<ProgressSpinner /></div
					></template>
					<template #empty>
						<div class="flex align-items-center justify-content-center">
							<div class="p-invalid">No existen Productos en el detalle!</div>
						</div>
					</template>
					<Column field="producto" header="DETALLE/PRODUCTO"></Column>
					<Column class="text-center" field="cantidad" header="CANTIDAD">
						<template #body="slotProps">
							{{ slotProps.data.cantidad }}
						</template>
						<template #editor="slotProps">
							<InputNumber
								:inputClass="'text-right'"
								:min="0"
								:minFractionDigits="0"
								locale="de-DE"
								v-model="slotProps.data.cantidad"
							/>
						</template>
					</Column>
					<Column header="ACCIONES">
						<template #body="slotProps">
							<Button
								@click="quitarProductoDetalle(slotProps)"
								v-tooltip.top="'Quitar Producto'"
								icon="pi pi-times"
								class="p-button-rounded p-button-danger p-button-text"
							/>
						</template>
					</Column>
				</DataTable>
			</div>
			<div class="p-fluid formgrid grid">
				<div class="field col-12 md:col-6">
					<label for=""><strong>Observaciones:</strong></label>
					<Textarea
						id="observacion"
						v-model="observacion"
						required="true"
						rows="2"
						cols="60"
						@onkeyup.enter="guardarMovimiento"
					>
					</Textarea>
				</div>
			</div>
		</div>
		<div
			class="flex align-items-end flex-column lg:justify-content-end lg:flex-row"
		>
			<Button
				label="Registrar Movimiento"
				icon="pi pi-save"
				class="p-button-primary p-button-lg"
				v-tooltip.top="'Registrar Movimiento'"
				@click="guardarMovimiento"
			/>
		</div>
	</div>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import TipoMovimientosService from "@/service/TipoMovimientos";

export default {
	data() {
		return {
			detalle_movalmacen: [],
			sucursalSelected: 1,
			sucursales: [],
			tipoMovimientos: [],
			tipoMovimientoSelected: 1,
			productoSelected: null,
			productosListadoFiltrado: [],
			fecha_movimiento: new Date(),
			observacion: null,
			enviando: false,
			errors: {},
		};
	},
	sucursalService: null,
	productService: null,
	tipoMovimientosService: null,
	created() {
		this.sucursalService = new SucursalService();
		this.productService = new ProductService();
		this.tipoMovimientosService = new TipoMovimientosService();
	},
	mounted() {
		this.cargarSucursales();
		this.cargarTipoMovimientos();
	},
	methods: {
		validarFormulario() {
			if (this.sucursalSelected == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Debe seleccionar una sucursal",
					life: 3000,
				});
				return false;
			}
			if (this.tipoMovimientoSelected.id == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Debe seleccionar un tipo de movimiento",
					life: 3000,
				});
				return false;
			}
			if (this.detalle_movalmacen.length == 0) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Debe agregar al menos un producto al detalle",
					life: 3000,
				});
				return false;
			}
			return true;
		},
		guardarMovimiento() {
			if (this.validarFormulario()) {
				this.enviando = true;
				let datos = {
					sucursal_id: this.sucursalSelected,
					tipo_movalmacen_id: this.tipoMovimientoSelected.id,
					tipo_movimiento: this.tipoMovimientoSelected.tipo,
					fecha_movimiento: this.fecha_movimiento,
					observacion: this.observacion,
					productos: this.detalle_movalmacen,
				};

				this.tipoMovimientosService
					.saveMovimientoAlmacen(datos)
					.then((data) => {
						if (data.status == 200) {
							this.$toast.add({
								severity: "success",
								summary: "Movimiento de Almacen",
								detail: data.mensaje,
								life: 3000,
							});
							this.enviando = false;
							this.detalle_movalmacen = [];
							this.observacion = "";
						} else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.mensaje,
                life: 3000,
              });
              this.enviando = false;
              this.detalle_movalmacen = [];
              this.observacion = "";
            }
					});
			}
		},
		guardandoCambiosCelda(e) {
			let { data, newValue, field } = e;
			data[field] = newValue;
		},
		agregarProductoDetalle() {
			if (this.tipoMovimientoSelected.id == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Debe seleccionar un tipo de movimiento",
					life: 3000,
				});
				return;
			}
			if (this.productoSelected == null) {
				this.$toast.add({
					severity: "error",
					summary: "Error",
					detail: "Debe seleccionar un producto",
					life: 3000,
				});
				return;
			}

			let existe = this.detalle_movalmacen.find(
				(item) => item.producto_id === this.productoSelected.id
			);

			if (existe) {
				this.errors.productoSelected = [
					"El producto ya se encuentra en el detalle",
				];
				return false;
			}

			if (this.cantidad <= 0 || this.cantidad === null) {
				this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
				return false;
			}

			let producto = this.productoSelected;
			let cantidad = 1;
			let detalle = {
				id: producto.id,
				producto: producto.descripcion,
				cantidad: cantidad,
			};
			this.detalle_movalmacen.push(detalle);
			this.productoSelected = null;
		},
		buscarProducto(event) {
			setTimeout(() => {
				this.productService
					.buscarXNombre({ texto: event.query })
					.then((response) => {
						this.productosListadoFiltrado = [...response.productos];
					})
					.catch((error) => console.log(error));
			}, 250);
		},
		cargarSucursales() {
			this.sucursalService
				.getSucursalesAll()
				.then((response) => {
					response.forEach((sucursal) => {
						this.sucursales.push(sucursal);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cargarTipoMovimientos() {
			this.tipoMovimientosService
				.getTipoMovimientosAll()
				.then((response) => {
					response.forEach((tipoMovimiento) => {
						this.tipoMovimientos.push(tipoMovimiento);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		quitarProductoDetalle(slotProps) {
			this.detalle_movalmacen.splice(slotProps.rowIndex, 1);
		},
	},
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
.p-fondo {
	border-radius: 1%;
}
</style>
