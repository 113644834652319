import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class TipoMovimientos {

	async getTipoMovimientosAll() {
		const tipos_movientos = await fetchWrapper.get(`${ruta}/tipo_movalmacen`);
		return tipos_movientos;
	}

	async getTiposMovimientosPorTipo(tipo) {
		//String con el tipo ej: Ingresos, Egresos
		return await fetchWrapper.post(`${ruta}/tipos_movientos/buscar`, tipo);
	}
  async saveMovimientoAlmacen(movimiento) {
    return await fetchWrapper.post(`${ruta}/movimientos_almacen`, movimiento);
  }
}